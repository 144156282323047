
@font-face {
    font-family: 'NobelWGL-Light';
    src: local('NobelWGL-Light'),
        url('../fonts/NobelWGL-Light.woff2') format('woff2'),
        url('../fonts/NobelWGL-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NobelWGL-Regular';
    src: local('NobelWGL-Regular'),
        url('../fonts/NobelWGL-Regular.woff2') format('woff2'),
        url('../fonts/NobelWGL-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NobelWGL-Bold';
    src: local('NobelWGL-Bold'),
        url('../fonts/NobelWGL-Bold.woff2') format('woff2'),
        url('../fonts/NobelWGL-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'NobelWGL-Book';
    src: local('NobelWGL-Book'),
        url('../fonts/NobelWGL-Book.woff2') format('woff2'),
        url('../fonts/NobelWGL-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NobelBookItalic';
    src: local('Nobel Book Italic'), local('NobelBookItalic'),
        url('../fonts/NobelBookItalic.woff2') format('woff2'),
        url('../fonts/NobelBookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

.super-hero {
	font: 85px/1 'NobelWGL-Regular';
	letter-spacing: 1px;
	text-transform: uppercase;
}

h1, .hero {
	font: 60px/64px 'NobelWGL-Regular';
	letter-spacing: 1px;
	text-transform: uppercase;
}

h2, .section-heading {
	font: 40px/44px 'NobelWGL-Regular';
	letter-spacing: 1.5px;
	text-transform: uppercase;
}

h3, .sub-heading {
	font: 26px/32px 'NobelWGL-Regular';
	text-transform: uppercase;
}

h4, .hero-small {
	font: 14px/28px 'NobelWGL-Regular';
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.quote {

}

.intro {
	font: 22px/40px 'NobelWGL-Light';
	letter-spacing: 0.5px;
}

.hero-small {

}

b, strong, .strong, .font-weight-bold {
	font-family: 'NobelWGL-Bold';
	letter-spacing: 0.5px;	
}

i, em, .font-italic {
	font-family: 'NobelBookItalic';
	letter-spacing: 0.5px;
}

small, .small {
	letter-spacing: 1px;	
}
