
.btn {
	position: relative;
	height: 58px;
	padding-left: 24px;
	padding-right: 73px;	// 24 margin + 25 squeeze + 24 margin
  text-align: left;
  font-family: 'NobelWGL-Bold';
 	font-size: 11px;
  text-transform: uppercase;
 	letter-spacing: 1px;
}

.btn-primary {
	min-width: 260px;
	border: 1px solid #1a1a1a;
	background-color: #1a1a1a;
	@include hover-focus {
    background-color: #1a1a1a;
  }
}

.btn-secondary {
	min-width: 260px;
	border: 1px solid #b9c0c2;
	color: #1a1a1a;
	background-color: transparent;
	@include hover-focus {
		color: #5b6164;
    background-color: transparent;
  }
}

.btn-tertiary {
	border: 1px solid transparent;
	background-color: transparent;
	color: #1a1a1a;
}

.btn-next,
.btn-previous {
  padding-right: 24px;
  border: 1px solid transparent;
  background-color: transparent;
  color: #1a1a1a;
}

.btn-previous {
  text-align: right;
}

// Animated button element
.squeeze-white, .squeeze-black {
	position: absolute;
	top: 27px;
	right: 24px;
  display: inline-block;
  width: 25px;
  overflow: hidden;
 	float: right;
}

.squeeze-black::after,
.squeeze-white::after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background: #000;
}

.squeeze-white::after {
  background: #fff;
}

.squeeze-next,
.squeeze-previous {
  display: block;
  width: 75px;
  overflow: hidden;
}

.squeeze-next {
  margin-left: auto;
}

.squeeze-next:before,
.squeeze-previous:before {
  content: "";
  display: block;
  margin-bottom: 10px;
  height: 1px;
  width: 75px;
  background: #000;
}

.btn:hover .squeeze-white::after,
.btn:hover .squeeze-black::after {
  animation: slide 1s ease-in-out infinite;
}

.btn:hover .squeeze-next::before {
  animation: next 1s ease-in-out infinite;
}

.btn:hover .squeeze-previous::before {
  animation: previous 1s ease-in-out infinite;
}

@keyframes slide{
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(25px, 0);
  }
  51% {
    transform: translate(-25px, 0);
  }
  100% {
    transform: translate(0px, 0);
  }
}

@keyframes next{
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(75px, 0);
  }
  51% {
    transform: translate(-75px, 0);
  }
  100% {
    transform: translate(0px, 0);
  }
}

@keyframes previous{
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-75px, 0);
  }
  51% {
    transform: translate(75px, 0);
  }
  100% {
    transform: translate(0px, 0);
  }
}

select {
  height: 50px !important;
  appearance: none;
}


input[type=text],
input[type=email],
input[type=password],
input[type=number],
input[type=date] {
  height: 50px;
}

// custom radio & checkboxes
input[type=radio],
input[type=checkbox] { display:none; } /* to hide the checkbox itself */
input[type=radio] + label:before,
input[type=checkbox] + label:before {
  margin-left: -1em;
  margin-right: 7px;
  display: inline-block;
  font-family: 'ss-lexus-responsive';
  font-size: 24px;
}

input[type=radio] + label:before { content: "\f15c"; position: relative; top: 3px; }
input[type=radio]:checked + label:before { content: "\f15b"; }
input[type=radio]:disabled + label:before { content: '\f15c'; color: rgba(185, 192, 194, .7)}
input[type=radio]:disabled + label { text-decoration: none; color: rgba(185, 192, 194, .7)}

input[type=checkbox] + label:before { content: "\f110"; position: relative; top: 3px; }
input[type=checkbox]:checked + label:before { content: "\f111"; }
input[type=checkbox]:disabled + label:before { content: '\f110'; color: rgba(185, 192, 194, .7)}
input[type=checkbox]:disabled + label { text-decoration: none; color: rgba(185, 192, 194, .7)}

.form-check-input:disabled~.form-check-label {
  color: rgba(91, 97, 100, .7);
}

.form-check-label,
.form-group label {
  font-family: 'NobelWGL-Regular';
  letter-spacing: 0.5px;
  color: #1a1a1a;
}


// custom select icon
.select-wrapper {
	display: block;
  position: relative;
}

.select-wrapper:after {
  content: "\f10a";
  font-family: 'ss-lexus-responsive' !important;
  font-size: 150%;
  color: #b9c0c2;
  position: absolute;
  bottom: 7px;
  right: 10px;
  pointer-events: none;
}
