@font-face {
    font-family: 'ss-lexus-responsive';
    src: local('ss-lexus-responsive'),
        url('../fonts/ss-lexus-responsive.woff2') format('woff2'),
        url('../fonts/ss-lexus-responsive.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ss-lexus-responsive' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-arrow-down:before { content: "\f101"; }
.icon-arrow-left:before { content: "\f102"; }
.icon-arrow-right:before { content: "\f103"; }
.icon-arrow-up:before { content: "\f104"; }
.icon-arrow:before { content: "\f105"; }
.icon-brochure:before { content: "\f106"; }
.icon-calculator:before { content: "\f107"; }
.icon-calendar:before { content: "\f108"; }
.icon-camera:before { content: "\f109"; }
.icon-change:before { content: "\f10a"; }
.icon-character-incisive-simplicity:before { content: "\f10b"; }
.icon-character-intriguing-elegance:before { content: "\f10c"; }
.icon-character-seamless-anticipation:before { content: "\f10d"; }
.icon-chat-bubble:before { content: "\f10e"; }
.icon-check:before { content: "\f10f"; }
.icon-checkbox-unselected:before { content: "\f110"; }
.icon-checkbox-selected:before { content: "\f111"; }
.icon-clock:before { content: "\f112"; }
.icon-close:before { content: "\f113"; }
.icon-compare:before { content: "\f114"; }
.icon-cycle-time:before { content: "\f115"; }
.icon-detail:before { content: "\f116"; }
.icon-device-desktop:before { content: "\f117"; }
.icon-device-phone:before { content: "\f118"; }
.icon-device-table:before { content: "\f119"; }
.icon-distance:before { content: "\f11a"; }
.icon-document:before { content: "\f11b"; }
.icon-download:before { content: "\f11c"; }
.icon-drag:before { content: "\f11d"; }
.icon-envelope:before { content: "\f11e"; }
.icon-environments:before { content: "\f11f"; }
.icon-expand:before { content: "\f120"; }
.icon-external-link:before { content: "\f121"; }
.icon-finance:before { content: "\f122"; }
.icon-find-location:before { content: "\f123"; }
.icon-gallery-label:before { content: "\f124"; }
.icon-gears:before { content: "\f125"; }
.icon-grid:before { content: "\f126"; }
.icon-information:before { content: "\f127"; }
.icon-lexus-bodyshop:before { content: "\f128"; }
.icon-lexus-build:before { content: "\f129"; }
.icon-lexus-calendar-servicereminder:before { content: "\f12a"; }
.icon-lexus-car-alt:before { content: "\f12b"; }
.icon-lexus-extendedwarranty:before { content: "\f12c"; }
.icon-lexus-fastfit:before { content: "\f12d"; }
.icon-lexus-hybrid:before { content: "\f12e"; }
.icon-lexus-insurance:before { content: "\f12f"; }
.icon-lexus-key:before { content: "\f130"; }
.icon-lexus-logo:before { content: "\f131"; }
.icon-lexus-mot:before { content: "\f132"; }
.icon-lexus-paintshop:before { content: "\f133"; }
.icon-lexus-partsshop:before { content: "\f134"; }
.icon-lexus-roadside-assistance:before { content: "\f135"; }
.icon-lexus-showroom:before { content: "\f136"; }
.icon-lexus-testtdrive:before { content: "\f137"; }
.icon-lexus-usedcars:before { content: "\f138"; }
.icon-lexus-warranty:before { content: "\f139"; }
.icon-lexus-workshop:before { content: "\f13a"; }
.icon-line:before { content: "\f13b"; }
.icon-login:before { content: "\f13c"; }
.icon-logo-android:before { content: "\f13d"; }
.icon-logo-apple:before { content: "\f13e"; }
.icon-logo-bluetooth:before { content: "\f13f"; }
.icon-logo-facebook-like:before { content: "\f140"; }
.icon-logo-facebook:before { content: "\f141"; }
.icon-logo-flickr:before { content: "\f142"; }
.icon-logo-google-plus:before { content: "\f143"; }
.icon-logo-instagram:before { content: "\f144"; }
.icon-logo-linkedin:before { content: "\f145"; }
.icon-logo-pinterest:before { content: "\f146"; }
.icon-logo-rss:before { content: "\f147"; }
.icon-logo-telegram:before { content: "\f148"; }
.icon-logo-twitter-retweet:before { content: "\f149"; }
.icon-logo-twitter:before { content: "\f14a"; }
.icon-logo-vk:before { content: "\f14b"; }
.icon-logo-windows:before { content: "\f14c"; }
.icon-logo-youtube-play:before { content: "\f14d"; }
.icon-logo-youtube:before { content: "\f14e"; }
.icon-map-pin:before { content: "\f14f"; }
.icon-map:before { content: "\f150"; }
.icon-menu:before { content: "\f151"; }
.icon-minus:before { content: "\f152"; }
.icon-more:before { content: "\f153"; }
.icon-no-matches:before { content: "\f154"; }
.icon-offers:before { content: "\f155"; }
.icon-pause:before { content: "\f156"; }
.icon-pen:before { content: "\f157"; }
.icon-play:before { content: "\f158"; }
.icon-plus:before { content: "\f159"; }
.icon-quick-action:before { content: "\f15a"; }
.icon-radio-selected:before { content: "\f15b"; }
.icon-radio-unselected:before { content: "\f15c"; }
.icon-radio:before { content: "\f15d"; }
.icon-read-more:before { content: "\f15e"; }
.icon-refresh:before { content: "\f15f"; }
.icon-replay:before { content: "\f160"; }
.icon-reply:before { content: "\f161"; }
.icon-resize-contract:before { content: "\f162"; }
.icon-resize-expand:before { content: "\f163"; }
.icon-save:before { content: "\f164"; }
.icon-search:before { content: "\f165"; }
.icon-select:before { content: "\f166"; }
.icon-share:before { content: "\f167"; }
.icon-sound-off:before { content: "\f168"; }
.icon-sound-on:before { content: "\f169"; }
.icon-speed:before { content: "\f16a"; }
.icon-star-full:before { content: "\f16b"; }
.icon-star-half:before { content: "\f16c"; }
.icon-stop:before { content: "\f16d"; }
.icon-temperature:before { content: "\f16e"; }
.icon-video-large:before { content: "\f16f"; }
.icon-video:before { content: "\f170"; }
.icon-warning:before { content: "\f171"; }


