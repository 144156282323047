/* Text */

.text-black, .black { color: #000; }
.text-white, .white { color: #fff; }

/* brand colours */
.text-naple-yellow, .naples-yellow { color: #f5b136; }
.text-solar-flare, .solar-flare { color: #db6024;}
.text-sonic-red, .sonic-red {color: #b12a31;}
.text-burgundy-red, .burgundy-red {color: #691c32;}
.text-azure-blue, .azure-blue { color: #20365c;}
.text-sky-blue, .sky-blue {color: #005fb6;}
.text-hybrid-blue, .hybrid-blue {color: #13248c}
.text-copper-brown, .copper-brown {color: #7c6756}
.text-sonic-titanium, .sonic-titanium {color: #a9aca1}
.text-teal-green, .teal-green {color: #659f13}

/* greys */
.text-lightest, .lightest {color: #f7f7f7}
.text-lighter, .lighter {color: #d5d8d9}
.text-light, .light {color: #b9c0c2}
.text-base, .base {color: #5b6164}
.text-dark, .dark {color: #191a1e}
.text-darkest, .darkest {color: #1a1a1a}


/* Background */

.bg-black { background-color: #000; }
.bg-white { background-color: #fff; }

/* brand colours */
.bg-naples-yellow, .naples-yellow-bg { background-color: #f5b136; }
.bg-solar-flare, .solar-flare-bg { background-color: #db6024;}
.bg-sonic-red, .sonic-red-bg {background-color: #b12a31;}
.bg-burgundy-red, .burgundy-red-bg {background-color: #691c32;}
.bg-azure-blue, .azure-blue-bg { background-color: #20365c;}
.bg-sky-blue, .sky-blue-bg {background-color: #005fb6;}
.bg-hybrid-blue, .hybrid-blue-bg {background-color: #13248c}
.bg-copper-brown, .copper-brown-bg {background-color: #7c6756}
.bg-sonic-titanium, .sonic-titanium-bg {background-color: #a9aca1}
.bg-teal-green, .teal-green-bg {background-color: #659f13}

/* greys */
.bg-lightest, .lightest-bg {background-color: #f7f7f7}
.bg-lighter, .lighter-bg {background-color: #d5d8d9}
.bg-light, .light-bg {background-color: #b9c0c2}
.bg-base, .base-bg {background-color: #5b6164}
.bg-dark, .dark-bg {background-color: #191a1e}
.bg-darkest, .darkest-bg {background-color: #1a1a1a}
